// 默认首页
export const DEFAULT_HOME = '/home';
// login
export const LOGIN_SIGN = '/login';
//
export const HOME = '/home';
// 店铺信息
export const SHOP_INFO_ADMIN = '/shop-info';
// 销售员管理
export const SALESMAN_ADMIN = '/salesman';
//微信用户管理
export const WECHAT_ADMIN = '/wechat'
// 车辆管理
export const CAR_ADMIN = '/car';
// 买卖车管理
export const CAR_DEAL_ADMIN = '/deal-car';
// 买车
export const BUY_CAR= '/buy-car';
// 卖车
export const SELL_CAR = '/sell-car';
// 估价
export const ASSESS_CAR = '/assess-car';
// 找车
export const LOOK_CAR = '/look-car';