<template>
  <el-config-provider :locale="locale">
    <router-view name="web" />
  </el-config-provider>
</template>

<script>
import {onMounted, reactive, toRefs} from "vue";
import zhCn from "element-plus/lib/locale/lang/zh-cn";
//
export default  {
  name:  'App',
  components: {
  },
  setup() {
    const state = reactive({
      locale: zhCn,
    })
    //
    onMounted(async () => {

    })

    return {
      ...toRefs(state),
    }
  },
}
</script>
<style>
	#app, body  {
		width: 100%;
		height: 100%;
		overflow: hidden;
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
    margin: 0;
	}
</style>
