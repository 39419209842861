export default {
    // 系统名称
    productName: '二手车-后台管理系统',
    baseUrl: {
        // 线上
        prod: 'https://test-car.fyee.top/api',
        // 本地
        dev: 'https://test-car.fyee.top/api',
    },
}
