import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router'
import {
  DEFAULT_HOME,
  LOGIN_SIGN,
  HOME,
  SHOP_INFO_ADMIN,
  SALESMAN_ADMIN,
  WECHAT_ADMIN,
  CAR_ADMIN,
  BUY_CAR,
  SELL_CAR, ASSESS_CAR, LOOK_CAR,
} from "./routePath.config";
import config from '@/utils/config'

const router = createRouter({
  // createWebHistory 路由模式路径不带#号(生产环境下不能直接访问项目，需要 nginx 转发)
  // createWebHashHistory 路由模式路径带#号
  history: createWebHashHistory(),
  routes: [
    {
      path: '/',
      redirect: DEFAULT_HOME
    },
    {
      path: LOGIN_SIGN,
      title: '登录',
      name: 'login',
      components: {
        web: () => import('@/views/login/index'),
      },
    },
    {
      path: HOME,
      redirect: SHOP_INFO_ADMIN,
      name: 'home',
      components: {
        web: () => import('@/views/home/index'),
      },
      children: [
        {
          path: SHOP_INFO_ADMIN,
          meta: {
            title: '信息管理',
          },
          name: 'shopInfo',
          components: {
            main: () => import('@/views/shopInfo/index'),
          },
        },
        {
          path: SALESMAN_ADMIN,
          meta: {
            title: '销售人员管理',
          },
          name: 'salesman',
          components: {
            main: () => import('@/views/salesman/index'),
          },
        },
        {
          path: WECHAT_ADMIN,
          meta: {
            title: '微信用户管理',
          },
          name: 'wechat',
          components: {
            main: () => import('@/views/wechat/index'),
          },
        },
        {
          path: CAR_ADMIN,
          meta: {
            title: '车辆管理',
          },
          name: 'carAdmin',
          components: {
            main: () => import('@/views/carAdmin/index'),
          },
        },
        {
          path: BUY_CAR,
          meta: {
            title: '买车信息',
          },
          name: 'buy',
          components: {
            main: () => import('@/views/dealCar/buy/index'),
          },
        },
        {
          path: SELL_CAR,
          meta: {
            title: '卖车信息',
          },
          name: 'sell',
          components: {
            main: () => import('@/views/dealCar/sell/index'),
          },
        },
        {
          path: ASSESS_CAR,
          meta: {
            title: '估价信息',
          },
          name: 'assess',
          components: {
            main: () => import('@/views/dealCar/assess/index'),
          },
        },
        {
          path: LOOK_CAR,
          meta: {
            title: '找车信息',
          },
          name: 'look',
          components: {
            main: () => import('@/views/dealCar/look/index'),
          },
        },
      ]
    },

  ]
})

// 解决 vue 中路由跳转时，总是从新页面中间开始显示
router.afterEach((to,from,next) => {
  window.scrollTo(0,0)
  // 浏览器title
  let adminName = config.productName || '';
  if (to && to.meta && to.meta.title) {
    window.document.title = adminName + '-' + to.meta.title;
  } else {
    window.document.title = adminName;
  }
})

export default router
