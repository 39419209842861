export default {
  state: {
    userInfo: null,
    // 公共配置
    commonConfig: {},
    // 字典数据
    dictInfo: {},
  },
  mutations: {
    SET_USER_INFO(state, value) {
      state.userInfo = value
    },
    SET_COMMON_CONFIG(state, value) {
      state.commonConfig = value
    },
    SET_DICT_INFO(state, value) {
      state.dictInfo = value
    },
  },
  actions: {
  },
  getters: {
    userInfo: state => state.userInfo,
    commonConfig: state => state.commonConfig,
    dictInfo: state => state.dictInfo,
  },
}
